import React, { useEffect, useState } from "react";
import BrandedApolloExplorer from "./components/BrandedApolloExplorer";
import {
  WithAuthenticatorProps,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import AuthHeader from "./components/AuthHeader";
import { ExplorerConfigUtils } from "./utils";
import { ReactComponent as DaphneLogo } from "./assets/daphne-logo-bw.svg";
import schema from "./schema";
import styles from "./styles.module.css";
import "@aws-amplify/ui-react/styles.css";

const App: React.FC = (props: WithAuthenticatorProps) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      // This is a hack to update the iframe src after the iframe has mounted
      ExplorerConfigUtils.updateIframeSrc({
        shouldShowGlobalHeader: false, // This removes the Apollo branding from the header
        parentSupportsSubscriptions: false, // This removes the subscription tab. We can enable this once we have subscriptions enabled in Daphne
        runTelemetry: false, // This stops the telemetry from running for the iframe
      });
    }, 1000);

    // Cleanup function to clear the timer
    setHasMounted(true);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.app}>
      {hasMounted ? (
        <BrandedApolloExplorer
          brandLogo={<DaphneLogo />}
          auth={props}
          exploreProps={{
            schema,
            endpointUrl: `https://${window.location.host}/api/graphql`,
            persistExplorerState: false,
            initialState: {
              displayOptions: {
                theme: "light",
              },
              document: `# Get a product by short code identifier
query GetProduct($code: ID!) {
  product(code: $code) {
    code
    name
  }
}

# Get investment products
query GetProducts {
  products {
    code
    name
    ... on InvestmentProductClosedEnded {
      shareClasses {
        code
        performance {
          asOfDate
          metric
          period
          gross
          net
        }
      }
    }
    ... on InvestmentProductOpenEnded {
      shareClasses {
        code
        performance {
          asOfDate
          metric
          period
          gross
          net
        }
      }
    }
  }
}

`,
              variables: {
                code: "ACME-EQV",
              },
            },
          }}
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default withAuthenticator(App, {
  loginMechanisms: ["email"],
  hideSignUp: true,
  components: {
    SignIn: {
      Header: () => <AuthHeader />,
    },
  },
});
