const schema = `scalar AWSDate
scalar AWSEmail
scalar AWSURL
scalar AWSTimestamp
scalar Long

"List of available time series data types."
enum TimeSeriesDataType {
  PERFORMANCE
  CASHFLOW
  VALUATION
  COMPOSITION
  CHARACTERISTIC
}

"List of available investment structures - used for filtering query results."
enum InvestmentProductStructure {
  INVESTMENT_PRODUCT_CLOSED_ENDED
  INVESTMENT_PRODUCT_OPEN_ENDED
}

type Mutation {

    createPerformanceData(input: [PerformanceInput]): [Performance]

    createValuationData(input: [ValuationInput]): [Valuation]

    createCashFlowData(input: [CashFlowInput]): [CashFlow]

    createCompositionData(input: [CompositionInput]): [Composition]

    createCharacteristicData(input: [CharacteristicInput]): [Characteristic]

}
type Query {

  "Query all products"  
  products: [Product]

  "Query single product by code"  
  product(code: ID!): Product

}

schema {
  query: Query
  mutation: Mutation
}

"This interface represents the data attributes that are common among all product types within Daphne."
interface Product {

  "The datetime at which this entity was originally created."
  createdAt: Long! #AWSTimestamp

  "The name of the user that initially created this entity."
  createdBy: String!

  "The datetime at which this entity was last updated."
  updatedAt: Long! #AWSTimestamp

  "The name of the user that last updated the this entity."
  updatedBy: String!

  "Unique GUID that is generated for each product created in Daphne."
  id: ID!

  "A user defined unique identifier for the product."
  code: String!

  "The name of the product."
  name: String!

  "The base currency of the product. For investment products this would be indicative of the portfolio's base currency."
  baseCurrency: CurrencyISOCode!

  "Short product headline description. Indicative length: 2 sentences."
  summary: String

  "Product description, enabling an Asset Manager to detail all of the relevant marketing details for the product."
  description: String

  "Captures general product disclaimers e.g. disclaimers linked to any statements made in the description."
  generalDisclaimer: String

  "The product support contact(s) to answer any questions related to the product."
  supportContacts: [String!]! #[AWSEmail!]!

  "List of documents that are associated with the product. Each document will have both a filename and a URL."
  documents: [Document!]!

  "The product that is considered to be a parent of this product. Used mainly for representing internal and external Master/Feeder relationships."
  parentProduct: Product

  "List of products that are considered to be children of this product. Used mainly for representing internal and external Master/Feeder relationships."
  childProducts: [Product!]!

  "Any additional information related to the product can be captured as items in this list."
  customAttributes: [CustomAttribute!]!

}
"This interface represents the data attributes that are common among all investment product types within Daphne."
interface InvestmentProduct {

  "List of external identifiers associated with the product. For example ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!

  "The investment type of the product."
  investmentType: InvestmentType

  "The asset class of this investment product."
  assetClass: AssetClass

  "The currency of the investment product."
  currency: CurrencyISOCode

  "The inception date of the investment product that reflects when it was created and available for marketing."
  inceptionDate: AWSDate

  "The regions within the geographical focus of the investment product."
  geographicalFocusRegion: [Region!]!

  "The countries within the geographical focus of the investment product."
  geographicalFocusCountry: [CountryISOCode!]!

  "One or many industries that the investment product targets."
  industry: [Industry!]!

  "List of the investment strategies associated with this product."
  investmentStrategies: [InvestmentStrategy!]!

  "List of the individuals that make up the fund manager team and are responsible for managing the investment strategy."
  fundManagerTeam: [Person!]!

  "The commitment from the general partner - a specific amount or a percentage or both may be captured."
  generalPartnerCommitment: GeneralPartnerCommitment 

  "Set of attributes that describe the target return and debt profile of the investment product."
  targets: Targets

  "Detail relating to the legal entity that owns this investment product."
  legalEntity: InvestmentProductLegalEntity

  "Detail relating to other legal entities that are involved in the management and maintainence of this product."
  associateLegalEntities: [AssociateLegalEntity!]!

}

"This type represents the data attributes that are relevant to open-ended investment structures."
type InvestmentProductOpenEnded implements Product & InvestmentProduct {

  "The datetime at which this entity was originally created."
  createdAt: Long! #AWSTimestamp

  "The name of the user that initially created this entity."
  createdBy: String!

  "The datetime at which this entity was last updated."
  updatedAt: Long! #AWSTimestamp

  "The name of the user that last updated the this entity."
  updatedBy: String!

  "Unique GUID that is generated for each product created in Daphne."
  id: ID!

  "A user defined unique identifier for the product."
  code: String!

  "The name of the product."
  name: String!

  "The base currency of the product. For investment products this would be indicative of the portfolio base currency."
  baseCurrency: CurrencyISOCode!

  "Short product headline description. Indicative length: 2 sentences."
  summary: String

  "Product description, enabling an Asset Manager to detail all of the relevant marketing details for the product."
  description: String

  "Captures general product disclaimers e.g. disclaimers linked to any statements made in the description."
  generalDisclaimer: String

  "The product support contact(s) to answer any questions related to the product."
  supportContacts: [String!]! #[AWSEmail!]!

  "List of documents that are associated with the product. Each document will have both a filename and a URL."
  documents: [Document!]!

  "The product that is considered to be a parent of this product. Used mainly for representing internal and external Master/Feeder relationships."
  parentProduct: InvestmentProductOpenEnded

  "List of products that are considered to be children of this product. Used mainly for representing internal and external Master/Feeder relationships."
  childProducts: [InvestmentProductOpenEnded!]!

  "Any additional information related to the product can be captured as items in this list."
  customAttributes: [CustomAttribute!]!

  "List of external identifiers associated with the product. For example ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!

  "The investment type of the product."
  investmentType: InvestmentType

  "The asset class of this investment product."
  assetClass: AssetClass

  "The currency of the investment product."
  currency: CurrencyISOCode

  "The inception date of the investment product that reflects when it was created and available for marketing."
  inceptionDate: AWSDate

  "The regions within the geographical focus of the investment product."
  geographicalFocusRegion: [Region!]!

  "The countries within the geographical focus of the investment product."
  geographicalFocusCountry: [CountryISOCode!]!

  "One or many industries that the investment product targets."
  industry: [Industry!]!

  "List of the investment strategies associated with this product."
  investmentStrategies: [InvestmentStrategy!]!

  "List of the individuals that make up the fund manager team and are responsible for managing the investment strategy."
  fundManagerTeam: [Person!]!

  "The commitment from the general partner - a specific amount or a percentage or both may be captured."
  generalPartnerCommitment: GeneralPartnerCommitment 

  "Set of attributes that describe the target return and debt profile of the investment product."
  targets: Targets

  "Detail relating to the legal entity that owns this investment product."
  legalEntity: InvestmentProductLegalEntity

  "Detail relating to other legal entities that are involved in the management and maintainence of this product."
  associateLegalEntities: [AssociateLegalEntity!]!

  "The funding status of the product - is it open for subscription?"
  fundingStatus: FundingStatus

  "The assets under management limit in the product that may potentially trigger a soft close."
  maximumOffering: CurrenciedAmount

  "List of share classes within the investment product. All products must have a default share class within Daphne."
  shareClasses: [ShareClassOpenEnded!]!

}

"This type represents the data attributes that are relevant to closed-ended investment structures."
type InvestmentProductClosedEnded implements Product & InvestmentProduct {

  "The datetime at which this entity was originally created."
  createdAt: Long! #AWSTimestamp!

  "The name of the user that initially created this entity."
  createdBy: String!

  "The datetime at which this entity was last updated."
  updatedAt: Long! #AWSTimestamp!

  "The name of the user that last updated the this entity."
  updatedBy: String!

  "Unique GUID that is generated for each product created in Daphne."
  id: ID!

  "A user defined unique identifier for the product."
  code: String!

  "The name of the product."
  name: String!

  "The base currency of the product. For investment products this would be indicative of the portfolio base currency."
  baseCurrency: CurrencyISOCode!

  "Short product headline description. Indicative length: 2 sentences."
  summary: String

  "Product description, enabling an Asset Manager to detail all of the relevant marketing details for the product."
  description: String

  "Captures general product disclaimers e.g. disclaimers linked to any statements made in the description."
  generalDisclaimer: String

  "The product support contact(s) to answer any questions related to the product."
  supportContacts: [String!]! #[AWSEmail!]!

  "List of documents that are associated with the product. Each document will have both a filename and a URL."
  documents: [Document!]!

  "The product that is considered to be a parent of this product. Used mainly for representing internal and external Master/Feeder relationships."
  parentProduct: InvestmentProductClosedEnded

  "List of products that are considered to be children of this product. Used mainly for representing internal and external Master/Feeder relationships."
  childProducts: [InvestmentProductClosedEnded!]!

  "Any additional information related to the product can be captured as items in this list."
  customAttributes: [CustomAttribute!]!

  "List of external identifiers associated with the product. For example ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!

  "The investment type of the product."
  investmentType: InvestmentType

  "The asset class of this investment product."
  assetClass: AssetClass

  "The currency of the investment product."
  currency: CurrencyISOCode
  
  "The inception date of the investment product that reflects when it was created and available for marketing."
  inceptionDate: AWSDate

  "The regions within the geographical focus of the investment product."
  geographicalFocusRegion: [Region!]!

  "The countries within the geographical focus of the investment product."
  geographicalFocusCountry: [CountryISOCode!]!

  "One or many industries that the investment product targets."
  industry: [Industry!]!

  "List of the investment strategies associated with this product."
  investmentStrategies: [InvestmentStrategy!]!

  "List of the individuals that make up the fund manager team and are responsible for managing the investment strategy."
  fundManagerTeam: [Person!]!

  "The commitment from the general partner - a specific amount or a percentage or both may be captured."
  generalPartnerCommitment: GeneralPartnerCommitment 

  "Set of attributes that describe the target return and debt profile of the investment product."
  targets: Targets

  "Detail relating to the legal entity that owns this investment product."
  legalEntity: InvestmentProductLegalEntity

  "Detail relating to other legal entities that are involved in the management and maintainence of this product."
  associateLegalEntities: [AssociateLegalEntity!]!

  "The stage of closed-ended lifecycle that the investment product is in."
  stage: Stage

  "The vintage year of the product captured as YYYY."
  vintageYear: Int

  "The waterfall method that is utilised within the investment product."
  waterfall: Waterfall
  
  "The estimated close date of the product."
  estimatedCloseDate: AWSDate

  "The full term of the investment product"
  productTermYears: Int

  "The potential extension of the investment product's term subject to approval e.g. 1 + 1"
  termExtension: String

  "The maximum amount the investment product is allowed to raise"
  hardcap: CurrenciedAmount

  "Target raise amount of the investment product"
  targetRaise: CurrenciedAmount

  "The current raise amount of the investment product"
  currentRaise: CurrenciedAmount

  "The closing schedule of the investment product"
  closingSchedule: [ClosingSchedule!]!

  "List of share classes within the investment product. All products must have a default share class within Daphne."
  shareClasses: [ShareClassClosedEnded!]!

}

"List of available investment types that can be associated with an investment product."
enum InvestmentType {
  FUND
  SECONDARY
  CO_INVESTMENT
  DIRECT_DEAL
  FUND_OF_FUNDS
  FUND_OF_SECONDARY_FUNDS
  FUND_OF_CO_INVESTMENTS
  INSURANCE_DEDICATED_FUND
}

"List of available asset classes that can be associated with an investment product."
enum AssetClass {
  PRIVATE_EQUITY
  PRIVATE_CREDIT
  REAL_ESTATE
  INFRASTRUCTURE
  NATURAL_RESOURCES
  HYBRID
}

"""
List of available asset classes that can be associated with an investment product.
Note that these values should all be mappable to an equivalent location in widely used standards like GICS and MSCI.
"""
enum Industry {
  CHEMICALS
  COMMERCIAL_SERVICES
  CONSTRUCTION
  CONSUMER_DURABLES
  CYBER
  EDUCATION
  ENERGY
  ESG
  FINANCIAL_SERVICES
  FINTECH
  FOOD_PRODUCTION
  FOOD_RETAIL
  FORESTRY
  GENERAL_TECHNOLOGY
  HEALTHCARE
  HOSPITALITY
  INDUSTRIAL_GOODS_AND_SERVICES
  INDUSTRY_AGNOSTIC
  INSURANCE
  MANUFACTURING
  MEDIA
  METALS_AND_MINERALS_PRODUCTION
  METALS_AND_MINING
  PHARMACEUTICALS
  PROFESSIONAL_SERVICES
  PUBLIC_SERVICES
  REAL_ESTATE
  RETAIL
  SUSTAINABILITY
  TELECOMMUNICATIONS
  TRANSPORT
  UTILITIES
}

"List of available investment strategies"
enum InvestmentStrategy {
  BUYOUTS
  DISTRESSED_FOR_CONTROL
  SPECIAL_SITUATIONS
  GENERAL_PARTNER_STAKES
  GROWTH_EQUITY
  VENTURE_CAPITAL
  EARLY_STAGE_VENTURE_CAPITAL
  MID_STAGE_VENTURE_CAPITAL
  LATE_STAGE_VENTURE_CAPITAL
  MULTI_STAGE_VENTURE_CAPITAL
  SECONDARY_INVESTMENTS
  SINGLE_GENERAL_PARTNER
  DIRECT_LENDING
  SENIOR_SECURED
  MEZZANINE
  DISTRESSED_CREDIT
  SPECIALITY_FINANCE
  VENTURE_DEBT
  ASSET_BACKED_LENDING
  INFRASTRUCTURE_CREDIT
  SUBORDINATED
  STRUCTURED_CREDIT
  STRUCTURED_EQUITY
  MULTI_STRATEGY
  RESIDENTIAL_PROPERTY
  OFFICE_PROPERTY
  HOSPITALITY_PROPERTY
  LIFE_SCIENCES_PROPERTY
  INDUSTRIAL_PROPERTY
  PROPERTY_DEBT_AND_LOANS
  RETAIL_PROPERTY
  OTHER_PROPERTY
  POWER_INFRASTRUCTURE
  RENEWABLES_INFRASTRUCTURE
  COMMUNICATIONS_INFRASTRUCTURE
  TRANSPORTATION_INFRASTRUCTURE
  SOCIAL_INFRASTRUCTURE
  MIDSTREAM_INFRASTRUCTURE
  FARM_LAND
  TIMBER_LAND
  RENEWABLE_ENERGY
  PRECIOUS_METALS
  COMMODITIES
  LOWER_MARKET
  MIDDLE_MARKET
  UPPER_MARKET
  CORE
  CORE_PLUS
  VALUE_ADDED
  OPPORTUNISTIC
  DISTRESSED 
  DIVERSIFIED
}

"The commitment from the general partner - a specific amount or a percentage or both may be captured."
type GeneralPartnerCommitment {

  "Total amount of investment provided by the general partner."
  generalPartnerCommitmentAmount: CurrenciedAmount

  "Percentage of the total amount of investment provided by the general partner."
  generalPartnerCommitmentPercentage: Float

}

"Set of attributes that describe the target return and debt profile of the investment product."
type Targets {

  "The gross and net targets for the 'internal rate of return' of the investment product."
  targetInternalRateOfReturn: GrossNetRange

  "The gross and net targets for the 'multiple on invested capital' of the investment product."
  targetMultipleOnInvestedCapital: GrossNetRange

  "The leverage range that the investment product is targeting."
  targetLeverage: FloatRange

  "The gross and net targets for the 'return on equity' of the investment product."
  targetReturnOnEquity: GrossNetRange
  
  "The expected number of investments that the product will make."
  targetNumberOfInvestments: IntegerRange

  "Any additional information or description relating to the target returns."
  targetReturnDescription: String

}

"The closing schedule for a closed-ended investment product."
type ClosingSchedule {

  "The date that the round opened."
  openingDate: AWSDate

  "The date that the round closed."
  closingDate: AWSDate

  "The amount that has been raised in this round to date."
  latestCloseAmount: CurrenciedAmount

  "The amount that the round targets to close at."
  targetCloseAmount: CurrenciedAmount

  "The description of the round (i.e. first close, second close)."
  closingDescription: String

  "Flag to determine if this is the final close date and amount."
  isFinalClose: Boolean

}

"""
Under a European waterfall structure carried interest is calculated at the investment product level across all deals. 
By contrast, an American waterfall is calculated on a deal-by-deal basis, where GPs are compensated for each successful deal.
"""
type Waterfall {

  "The type of waterfall methodology in use (American or European)."
  type: WaterfallType

  "Is the waterfall methodology a standard American or European waterfall or has it been modified?"
  isModified: Boolean

  "If the waterfall has been modified - this field should contain detail on the modifications."
  additionalInformation: String

}

"List of available waterfall types."
enum WaterfallType {
  AMERICAN
  EUROPEAN
}

"List of available funding statuses for open-ended investment products."
enum FundingStatus {
  OPEN
  CLOSED
  CLOSED_TO_NEW_SUBSCRIPTIONS
}

"List of available stages within the lifecycle of a closed-ended investment product."
enum Stage {
  FUNDRAISING
  FUNDRAISING_AND_INVESTING
  INVESTING
  HARVESTING
  FULLY_REALIZED
  WOUND_DOWN
}

"This interface represents the data attributes that are common to all share class types in Daphne."
interface ShareClass {

  "The datetime at which this entity was originally created."
  createdAt: Long #AWSTimestamp!

  "The name of the user that initially created this entity."
  createdBy: String!

  "The datetime at which this entity was last updated."
  updatedAt: Long #AWSTimestamp!

  "The name of the user that last updated the this entity."
  updatedBy: String!

  "Unique GUID that is generated for each share class created in Daphne."
  id: ID!

  "A user defined unique identifier for the share class."
  code: String!

  "The code of the product that owns this share class."
  productCode: String!

  "List of external identifiers associated with the share class. For example ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!

  "The name of the share class."
  name: String!

  "The currency of the share class."
  currency: CurrencyISOCode

  "The inception date of the share class that reflects when it was created and available for marketing."
  inceptionDate: AWSDate

  "Flag that determines whether or not the share class is considered to be the representative class for the investment product. Each product must have one representative share class."
  isRepresentativeClass: Boolean

  "The investor types who can invest in this specific class."
  investorTypes: [InvestorTypes!]!

  "Any additional clarifications on investor type restrictions for the share class."
  investorTypeAdditionalInformation: String

  "The minimum amount allowed to be invested in the class."
  nonQualifiedMinimumInvestment: CurrenciedAmount

  "The minimum amount allowed to be invested where the investment is happening via a retirement plan pre-tax."
  qualifiedMinimumInvestment: CurrenciedAmount

  "Investors continue to receive 100% of classes proceeds until the it has achieved its hurdle rate."
  hurdleRate: Float

  "Does the share class have a high water mark?"
  hasHighWatermark: Boolean

  "Amount that the general partner will receive of their original investment once hurdle rate achieved."
  catchUp: Float

  "Carried interest determines the percentage of profit made on the returns of a class that goes to the asset manager."
  carriedInterestRate: Float

  "Attribute to decribe if this unit reinvests dividends or distributes them as income to the distribution schedule."
  dividendTreatment: UnitType

  "The schedule according to which dividends and other distributions are returned to investors."
  distributionSchedule: CalendarSchedule

  "The frequency at which the class is priced."
  valuationFrequency: Frequency

  "List of management fees that are associated with this class."
  managementFees: [ManagementFee!]!

  "List of any additional fees that apply to the class."
  additionalFees: [AdditionalFee!]!

  "Any additional information relating to the class."
  additionalInformation: String

  "List of performance measurements associated with this share class."
  performance: [Performance!]!

  "List of the contributions and distributions associated with this share class."
  cashFlows: [CashFlow!]!

  "List of the price and valuation data associated with this share class."
  valuations: [Valuation!]!

  "List of the user defined characteristics associated with this share class."
  characteristics: [Characteristic!]!

  "List of the user defined compositions associated with this share class."
  compositions: [Composition!]!

}

"This type represents the data attributes that are relevant to open-ended share classes."
type ShareClassOpenEnded implements ShareClass {

  "The datetime at which this entity was originally created."
  createdAt: Long #AWSTimestamp!

  "The name of the user that initially created this entity."
  createdBy: String!

  "The datetime at which this entity was last updated."
  updatedAt: Long #AWSTimestamp!

  "The name of the user that last updated the this entity."
  updatedBy: String!

  "Unique GUID that is generated for each share class created in Daphne."
  id: ID!

  "A user defined unique identifier for the share class."
  code: String!

  "The code of the product that owns this share class."
  productCode: String!

  "List of external identifiers associated with the share class. For example ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!

  "The name of the share class."
  name: String!

  "The currency of the share class."
  currency: CurrencyISOCode

  "The inception date of the share class that reflects when it was created and available for marketing."
  inceptionDate: AWSDate

  "Flag that determines whether or not the share class is considered to be the representative class for the investment product. Each product must have one representative share class."
  isRepresentativeClass: Boolean

  "The investor types who can invest in this specific class."
  investorTypes: [InvestorTypes!]!

  "Any additional clarifications on investor type restrictions for the share class."
  investorTypeAdditionalInformation: String

  "The minimum amount allowed to be invested in the class."
  nonQualifiedMinimumInvestment: CurrenciedAmount

  "The minimum amount allowed to be invested where the investment is happening via a retirement plan pre-tax."
  qualifiedMinimumInvestment: CurrenciedAmount

  "Investors continue to receive 100% of classes proceeds until the it has achieved its hurdle rate."
  hurdleRate: Float

  "Does the share class have a high water mark?"
  hasHighWatermark: Boolean
  
  "Amount that the general partner will receive of their original investment once hurdle rate achieved."
  catchUp: Float

  "Carried interest determines the percentage of profit made on the returns of a class that goes to the asset manager."
  carriedInterestRate: Float

  "Attribute to decribe if this unit reinvests dividends or distributes them as income to the distribution schedule."
  dividendTreatment: UnitType

  "The schedule according to which dividends and other distributions are returned to investors."
  distributionSchedule: CalendarSchedule

  "The frequency at which the class is priced."
  valuationFrequency: Frequency

  "List of management fees that are associated with this class."
  managementFees: [ManagementFee!]!
  
  "List of any additional fees that apply to the class."
  additionalFees: [AdditionalFee!]!

  "Any additional information relating to the class."
  additionalInformation: String

  "Details relating to the lock up period of the class."
  lockUp: LockUp

  "Details relating to any redemption gates that may exist for the class."
  redemptionGates: [RedemptionGate!]!

  "The redemption schedule for the class."
  redemptionSchedule: RegularSchedule

  "The subscription schedule for the class."
  subscriptionSchedule: RegularSchedule

  "List of performance measurements associated with this share class."
  performance: [Performance!]!

  "List of the contributions and distributions associated with this share class."
  cashFlows: [CashFlow!]!

  "List of the price and valuation data associated with this share class."
  valuations: [Valuation!]!

  "List of the user defined characteristics associated with this share class."
  characteristics: [Characteristic!]!

  "List of the user defined compositions associated with this share class."
  compositions: [Composition!]!

}

"This type represents the data attributes that are relevant to closed-ended share classes."
type ShareClassClosedEnded implements ShareClass {

  "The datetime at which this entity was originally created."
  createdAt: Long #AWSTimestamp!

  "The name of the user that initially created this entity."
  createdBy: String!

  "The datetime at which this entity was last updated."
  updatedAt: Long #AWSTimestamp!

  "The name of the user that last updated the this entity."
  updatedBy: String!

  "Unique GUID that is generated for each share class created in Daphne."
  id: ID!

  "A user defined unique identifier for the share class."
  code: String!

  "The code of the product that owns this share class."
  productCode: String!

  "List of external identifiers associated with the share class. For example ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!

  "The name of the share class."
  name: String!

  "The currency of the share class."
  currency: CurrencyISOCode

  "The inception date of the share class that reflects when it was created and available for marketing."
  inceptionDate: AWSDate

  "Flag that determines whether or not the share class is considered to be the representative class for the investment product. Each product must have one representative share class."
  isRepresentativeClass: Boolean

  "The investor types who can invest in this specific class."
  investorTypes: [InvestorTypes!]!

  "Any additional clarifications on investor type restrictions for the share class."
  investorTypeAdditionalInformation: String

  "The minimum amount allowed to be invested in the class."
  nonQualifiedMinimumInvestment: CurrenciedAmount

  "The minimum amount allowed to be invested where the investment is happening via a retirement plan pre-tax."
  qualifiedMinimumInvestment: CurrenciedAmount

  "Investors continue to receive 100% of classes proceeds until the it has achieved its hurdle rate."
  hurdleRate: Float

  "Does the share class have a high water mark?"
  hasHighWatermark: Boolean

  "Amount that the general partner will receive of their original investment once hurdle rate achieved."
  catchUp: Float

  "Carried interest determines the percentage of profit made on the returns of a class that goes to the asset manager."
  carriedInterestRate: Float

  "Attribute to decribe if this unit reinvests dividends or distributes them as income to the distribution schedule."
  dividendTreatment: UnitType

  "The schedule according to which dividends and other distributions are returned to investors."
  distributionSchedule: CalendarSchedule

  "The frequency at which the class is priced."
  valuationFrequency: Frequency

  "List of management fees that are associated with this class."
  managementFees: [ManagementFee!]!

  "List of any additional fees that apply to the class."
  additionalFees: [AdditionalFee!]!

  "Any additional information relating to the class."
  additionalInformation: String

  "List of performance measurements associated with this share class."
  performance: [Performance!]!

  "List of the contributions and distributions associated with this share class."
  cashFlows: [CashFlow!]!

  "List of the price and valuation data associated with this share class."
  valuations: [Valuation!]!

  "List of the user defined characteristics associated with this share class."
  characteristics: [Characteristic!]!

  "List of the user defined compositions associated with this share class."
  compositions: [Composition!]!

}

"Definition of a lock up period"
type LockUp {

  "The type of lock up that is being applied."
  lockUpType: LockUpType

  "The duration of the lock up period measured in years. e.g. 6 months represented as 0.5yrs"
  durationYears: Float

  "Any addtional detail relating to the lock up period."
  description: String

}

"List of available lock up types."
enum LockUpType {
  SOFT_LOCK
  HARD_LOCK
}

enum UnitType {
  INCOME
  ACCUMULATION
}

"Definition of a redemption gate."
type RedemptionGate {

  "The type of the redemption gate."
  gateType: GateType

  "The percentage of a redemption above which the gate will apply." 
  gatePercentage: Float

  "The frequency at which the redemption gate applies."
  gateFrequency: Frequency

  "The frequency type associated with the redemption gate."
  gateFrequencyType: GateFrequencyType

  "Any additional information associated with redemption gate."
  gateAdditionalInformation: String

}

"List of available redemption gate types."
enum GateType {
  FUND_LEVEL
  CLASS_LEVEL
  INVESTOR_LEVEL
}

"List of available redemption gate frequency types."
enum GateFrequencyType {
  FISCAL
  ROLLING
}

"List of available investor types."
enum InvestorTypes {
  QUALIFIED_PURCHASER
  QUALIFIED_CLIENT
  ACCREDITED_INVESTOR
  INSTITUTIONAL_INVESTOR
  KNOWLEDGEABLE_EMPLOYEE
  EU_PROFESSIONAL_CLIENT
  RETIREMENT_PLAN
  NON_ACCREDITED_OR_RETAIL
}

interface TimeSeriesData {

  "The name of the user that initially created this record."
  createdBy: String!

  "The date that this record applies to."
  asOfDate: AWSDate!

}

input PerformanceInput {
    dataType: TimeSeriesDataType!
    productCode: String!
    shareClassCode: String!
    asOfDate: AWSDate!
    isDeleted: Boolean!
    metric: MetricType!
    period: MetricPeriod!
    isAnnualized: Boolean!
    dividendBasis: DividendBasisType!
    currencyBasis: CurrencyISOCode!
    taxBasis: TaxBasis!
    salesLoadBasis: SalesLoadBasis!
    priceBasis: PriceBasis!
    gross: Float
    net: Float
    additionalInformation: String
}

type Performance implements TimeSeriesData {
 
  "The name of the user that initially created this record."
  createdBy: String!

  "The date that this record applies to."
  asOfDate: AWSDate!
  
  "The metric that is being reported on."
  metric: MetricType!

  "The period over which the measurement has been captured."
  period: MetricPeriod!

  "Flag that signifies if the measurement has been annualized. Note that a value of false signifies that the measurement is cumulative."
  isAnnualized: Boolean!

  "Attribute to determine how dividends are treated in this specific measurement if applicable."
  dividendBasis: DividendBasisType!

  "The currency basis of the measurement."
  currencyBasis: CurrencyISOCode!

  "Attribute to determine whether the values captured are gross or net of tax."
  taxBasis: TaxBasis!

  "Attribute to determine whether the values captured are gross or net of sales load."
  salesLoadBasis: SalesLoadBasis!

  "Attribute to determine the underlying price that was used in the performance calculation"
  priceBasis: PriceBasis!

  "The value for the measurement gross of fees."
  gross: Float
  
  "The value for the measurement net of fees."
  net: Float

  "Any additional information relating to this report."
  additionalInformation: String

}

"List of available metrics that a report can be created on."
enum MetricType {
  INTERNAL_RATE_OF_RETURN
  MODIFIED_INTERNAL_RATE_OF_RETURN
  RETURN_ON_EQUITY
  MULTIPLE_ON_INVESTED_CAPITAL
  DISTRIBUTED_TO_PAID_IN_CAPITAL
  RESIDUAL_VALUE_TO_PAID_IN_CAPITAL
  INCOME_RETURN
  APPRECIATION_RETURN
  DISTRIBUTION_RATE
  DISTRIBUTION_PER_SHARE_AMOUNT
  SPECIAL_DISTRIBUTION_PER_SHARE_AMOUNT
  TIME_WEIGHTED_RETURN 
  PRICE_CHANGE_AMOUNT
}

"List of available periods that a report measurement can be made over."
enum MetricPeriod {
  ONE_DAY
  ONE_WEEK
  TWO_WEEKS
  MONTH_TO_DATE
  ONE_MONTH
  TWO_MONTHS
  QUARTER_TO_DATE
  THREE_MONTHS
  SIX_MONTHS
  NINE_MONTHS
  YEAR_TO_DATE
  ONE_YEAR
  EIGHTEEN_MONTHS
  TWO_YEARS
  THREE_YEARS
  FOUR_YEARS
  FIVE_YEARS
  SIX_YEARS
  SEVEN_YEARS
  EIGHT_YEARS
  NINE_YEARS
  TEN_YEARS
  FIFTEEN_YEARS
  TWENTY_YEARS
  TWENTY_FIVE_YEARS
  INCEPTION_TO_DATE
}

enum DividendBasisType {
  PRICE_RETURN
  TOTAL_RETURN
  TOTAL_RETURN_DEC_DATE
  TOTAL_RETURN_EXD_DATE
  TOTAL_RETURN_PAY_DATE
  NOT_APPLICABLE
}

enum TaxBasis {
  GROSS
  NET
}

enum SalesLoadBasis {
  GROSS
  NET
  NOT_APPLICABLE
}

enum PriceBasis {
  NET_ASSET_VALUE_PER_UNIT_SHARE
  MARKET_OPEN
  MARKET_CLOSE
  OFFER_PRICE
  MID_PRICE
  BID_PRICE
}

input CashFlowInput {
    dataType: TimeSeriesDataType!
    productCode: String!
    shareClassCode: String!
    asOfDate: AWSDate!
    isDeleted: Boolean!
    cashFlowType: CashFlowType!
    currencyBasis: CurrencyISOCode!
    declarationDate: AWSDate
    exDividendDate: AWSDate
    paymentDate: AWSDate
    taxBasis: TaxBasis!
    gross: Float
    net: Float
    isEstimate: Boolean!
    additionalInformation: String
}

"Type for capturing the contribution and distribution data for an investment product."
type CashFlow implements TimeSeriesData {

  "The name of the user that initially created this record."
  createdBy: String!

  "The date that this record applies to."
  asOfDate: AWSDate!
  
  "The type of cash flow being described."
  cashFlowType: CashFlowType!

  "The currency in which the cash flow amounts are expressed."
  currencyBasis: CurrencyISOCode!

  "The date on which the dividend was announced. Only relevant for dividend distributions."
  declarationDate: AWSDate

  "The date on which the nav per unit share goes ex-dividend. Only relevant for dividend distributions."
  exDividendDate: AWSDate

  "The date on which the dividend is paid. Only relevant for dividend distributions."
  paymentDate: AWSDate

  "Attribute to determine whether the values captured are gross or net of tax."
  taxBasis: TaxBasis

  "The value for the measurement gross of fees."
  gross: Float

  "The value for the measurement net of fees."
  net: Float

  "Flag to determine if the values are a projected estimate or a finalized value."
  isEstimate: Boolean

  "Any additional information relating to this cash flow."
  additionalInformation: String

}

enum CashFlowType {
  CONTRIBUTION_CAPITAL_CALL
  CONTRIBUTION_FOLLOW_ON_CAPITAL_CALL
  CONTRIBUTION_COVER_CALL
  CONTRIBUTION_REINVESTMENT_OF_DIVIDEND_OR_INCOME
  DISTRIBUTION_DIVIDEND_OR_INCOME
  DISTRIBUTION_SPECIAL_DIVIDEND
  DISTRIBUTION_RETURN_OF_CAPITAL
  DISTRIBUTION_CAPITAL_GAIN
  DISTRIBUTION_LIQUIDATION_DISTRIBUTION
  DISTRIBUTION_CLAWBACK
}

input ValuationInput {
    dataType: TimeSeriesDataType!
    productCode: String!
    shareClassCode: String!
    asOfDate: AWSDate!
    isDeleted: Boolean!
    valuationType: ValuationType!
    currencyBasis: CurrencyISOCode!
    salesLoadBasis: SalesLoadBasis!
    feeBasis: FeeBasis!
    value: Float
    isEstimate: Boolean
    additionalInformation: String
}

"Type for capturing the valuation data for an investment product."
type Valuation implements TimeSeriesData {

  "The name of the user that initially created this record."
  createdBy: String!

  "The date that this record applies to."
  asOfDate: AWSDate!
  
  "The type of valuation being captured in this record."
  valuationType: ValuationType!

  "The currency basis of the valuation measurement."
  currencyBasis: CurrencyISOCode!

  "Attribute to determine whether the values captured are gross or net of sales load."
  salesLoadBasis: SalesLoadBasis!

  "Attribute to determine whether the values captured are gross or net of fees."
  feeBasis: FeeBasis!

  "The value associated with this record."
  value: Float

  "Flag that signifies whether the valuation is an estimate or an actual measurement."
  isEstimate: Boolean!

  "Any additional information relating to this valuation."
  additionalInformation: String

}

enum FeeBasis {
  GROSS
  NET
  NOT_APPLICABLE
}

enum ValuationType {
  NET_ASSET_VALUE_PER_UNIT_SHARE
  BID_PRICE
  MID_PRICE
  OFFER_PRICE
  MARKET_OPEN
  MARKET_CLOSE
  UNITS_IN_ISSUE
  TOTAL_NET_ASSETS
  GROSS_ASSET_VALUE
}

input CompositionInput {
    dataType: TimeSeriesDataType!
    productCode: String!
    shareClassCode: String!
    asOfDate: AWSDate!
    isDeleted: Boolean!
    compositionName: String!
    currencyBasis: CurrencyISOCode!
    includesCash: Boolean!
    valueType: CompositionValueTypes!
    valueBasis: CompositionValueBasis!
    additionalInformation: String
    segmentName: String!
    longValue: Float
    shortValue: Float
    targetValueMinimum: Float
    targetValueMaximum: Float
    segmentMetaData1: String
    segmentMetaData2: String
    segmentMetaData3: String
}

"Type for capturing the portfolio composition data for an investment product."
type Composition implements TimeSeriesData {

  "The name of the user that initially created this record."
  createdBy: String!

  "The date that this record applies to."
  asOfDate: AWSDate!
  
  "The name of the composition being captured."
  compositionName: String!

  "The currency of the composition data - if applicable."
  currencyBasis: CurrencyISOCode!

  "Flag to determine whether the compositional data includes cash."
  includesCash: Boolean!

  "The type associated with the values of individual segments in the composition."
  valueType: CompositionValueTypes!

  "The basis of the values of individual segments in the composition."
  valueBasis: CompositionValueBasis!

  "Any additional information relating to this composition."
  additionalInformation: String

  "The name of this specific segment within the composition."
  segmentName: String!

    "The long value of the segment."
  longValue: Float

  "The short value of the segment."
  shortValue: Float

  "The lower limit for the target range of the segment if applicable."
  targetValueMinimum: Float

  "The upper limit for the target range of the segment if applicable."
  targetValueMaximum: Float

  "Field for capturing additional information relating to the segment."
  segmentMetaData1: String

  "Field for capturing additional information relating to the segment."
  segmentMetaData2: String

  "Field for capturing additional information relating to the segment."
  segmentMetaData3: String

}

enum CompositionValueTypes {
  PERCENTAGE
  CURRENCIED_AMOUNT
}

enum CompositionValueBasis {
  MARKET_VALUE
  EXPOSURE
}

input CharacteristicInput {
    dataType: TimeSeriesDataType!
    productCode: String!
    shareClassCode: String!
    asOfDate: AWSDate!
    isDeleted: Boolean!
    name: String!
    currencyBasis: CurrencyISOCode!
    numericType: NumericType
    numericValue: Float
    textValue: String
    additionalInformation: String
}

type Characteristic implements TimeSeriesData {

  "The name of the user that initially created this record."
  createdBy: String!

  "The date that this record applies to."
  asOfDate: AWSDate!
  
  "The name of the characteristic being captured."
  name: String!

  "The currency of the characteristic if applicable."
  currencyBasis: CurrencyISOCode!

  "If the characteristic is a numeric value this field should be used to contextualize the data."
  numericType: NumericType

  "If the characteristic is a numeric value this field should be used to capture the data."
  numericValue: Float

  "If the characteristic is a text value this field should be used to capture the data."
  textValue: String

  "Any additional information relating to this characteristic."
  additionalInformation: String

}

enum NumericType {
  TIME_DAYS
  TIME_MONTHS
  TIME_YEARS
  COUNT
  PERCENTAGE
  BASIS_POINTS
  MULTIPLE
  CURRENCIED_AMOUNT
}

"This type represents the attributes required to define a management fee."
type ManagementFee {

  "The structure of the fee."
  structure: FeeStructure

  "The frequency at which the fee is to be paid."
  paymentFrequency: PaymentFrequency

  "The rate of the fee."
  rate: Float

  "Any additional information associated with the fee."
  additionalInformation: String

  "Flag to determine whether or not this is the main management fee that is used for marketing purposes."
  isHeadlineFee: Boolean

}

"This type represents the attributes required to define other additional fees."
type AdditionalFee {
  
  "The type of the fee being captured."
  type: FeeType

  "The name of the fee. This should be used to label the fee when 'other' has been selected as the fee type."
  name: String 

  "The structure of the fee."
  structure: FeeStructure

  "The frequency at which the fee is to be paid."
  paymentFrequency: PaymentFrequency

  "The rate of the fee."
  rate: Float

  "Any additional information associated with the fee."
  additionalInformation: String
}

"List of available fee types."
enum FeeType {
  TRAIL
  BROKERAGE
  PLACEMENT
  TRANSACTION
  ADMINISTRATION
  SERVICING
  CUSTODY
  SPONSOR
  EXIT 
  INCENTIVE
  PASS_THROUGH
  SALES_LOAD
  OTHER
}

"List of available fee structures."
enum FeeStructure {
  SINGLE_RATE
  TIERED_RATE
}

"List of available fee payment frequencies."
enum PaymentFrequency {
  MONTHLY_IN_ADVANCE
  MONTHLY_IN_ARREARS
  QUARTERLY_IN_ADVANCE
  QUARTERLY_IN_ARREARS
  SEMI_ANNUALLY_IN_ADVANCE
  SEMI_ANNUALLY_IN_ARREARS
  ANNUALLY_IN_ADVANCE
  ANNUALLY_IN_ARREARS
  ONCE_ON_SUBSCRIPTION
  ONCE_ON_REDEMPTION
  ONCE_ON_TRANSFER
  ONCE_OFF
  AD_HOC
}

"Legal entity details that are associated with an investment product."
type InvestmentProductLegalEntity {

  "The name of the legal entity."
  legalName: String

  "The legal entity identifier of the entity."
  legalEntityIdentifier: String

  "The structure of the legal entity."
  legalStructure: LegalStructure

  "The country in which the legal entity is based."
  jurisdictionCountry: CountryISOCode

  "The US state in which the legal entity is based."
  jurisdictionState: StateISOCode

  "A description of the legal entity."
  description: String

  "Tax eligibility of investors able to invest in the fund"
  investorTaxEligibility: InvestorTaxEligibility

  "Type of tax reporting required for the investment product"
  taxReportingDocumentation: [TaxReportingDocumentation!]!

}

"Legal entity details that are associated with companies involved in the management of the investment product."
type AssociateLegalEntity {

  "The name of the legal entity."
  legalName: String

  "The legal entity identifier of the entity."
  legalEntityIdentifier: String

  "The country in which the legal entity is based."
  jurisdictionCountry: CountryISOCode

  "A description of the legal entity."
  description: String

  "The role that legal entity carries out."
  role: LegalRole

}

"List of available legal roles for defined legal entities."
enum LegalRole {
  GENERAL_PARTNER
  FUND_MANAGEMENT_COMPANY
  INVESTMENT_ADVISER
  SUB_ADVISER
  FUND_ADMINISTRATOR
  FUND_ACCOUNTANT
  TRANSFER_AGENT
  CUSTODIAN
  DEPOSITORY
  THIRD_PARTY_MANCO_SERVICE_PROVIDER
}

"List of available legal structures for defined legal entities."
enum LegalStructure {
  BUSINESS_DEVELOPMENT_COMPANY
  BUSINESS_TRUST
  GENERAL_PARTNERSHIP
  INTERVAL
  INVESTMENT_TRUST
  LIMITED_LIABILITY_COMPANY
  LIMITED_PARTNERSHIP
  NON_TRADED_REAL_ESTATE_INVESTMENT_TRUST
  REAL_ESTATE_INVESTMENT_TRUST
  SPECIAL_LIMITED_PARTNERSHIP
  UNIT_TRUST
  COMPANY
  OTHER
}

"List of available options for tax eligibility of investors."
enum InvestorTaxEligibility {
  US_TAXABLE
  US_TAX_EXEMPT
  NON_US
}

"List of tax documentation that may be required for the product."
enum TaxReportingDocumentation {
  FORM_1099
  FORM_K1
  NOT_APPLICABLE
}

"This type defines a schedule that occurs at regular intervals for set periods of time."
type RegularSchedule {

  "The frequency at which the schedule applies."
  frequency: Frequency

  "Any additional information relating to the schedule."
  additionalInformation: String

  "The offset between the start of the period and the start of the schedule being defined."
  offset: Days

  "The length of the period being described."
  window: Days

}

"This type defines a schedule that highlights specific calendar dates."
type CalendarSchedule {

  "The frequency at which the schedule applies."
  frequency: Frequency!

  "List of dates associated with this calendar schedule."
  dates: [AWSDate!]!

  "Any additional information relating to the schedule."
  additionalInformation: String

}

"Type that is used for defining a number of days in a period."
type Days {

  "The type of day that this value related to."
  dayType: DayType!

  "The number of days."
  value: Int!

}

"List of available day types."
enum DayType {
  BUSINESS_DAY
  CALENDAR_DAY
}

"Type used for defining a persons name and role."
type Person {
  
  "The first name of the person."
  firstName: String

  "The last name of the person."
  lastName: String

  "The role that the individual performs."
  role: String

}

"Type used for capturing a value and it's associated currency."
type CurrenciedAmount {

  "The amount to be captured."
  amount: Float

  "The currency associated with the amount being captured."
  currency: CurrencyISOCode

}

"List of available frequency types."
enum Frequency {
  DAILY
  WEEKLY
  FORTNIGHTLY
  SEMI_MONTHLY
  MONTHLY
  QUARTERLY
  SEMI_ANNUALLY
  ANNUALLY
  AD_HOC
}

"Type for capturing a user defined attribute."
type CustomAttribute {

  "The name of the custom attribute being captured."
  key: String!

  "The value of the custom attribute being captured."
  value: String!

  "The type of data being captured within this custom attribute."
  type: AttributeType!

}

"List of available attribute types."
enum AttributeType {
  INT
  FLOAT
  STRING
  BOOLEAN
  DATE
  DATETIME
}

"Type for capturing a net and gross range banded by floats."
type GrossNetRange {

  "The minimum net value of the range."
  netMinimum: Float

  "The maximum net value of the range."
  netMaximum: Float

  "The minimum gross value of the range."
  grossMinimum: Float

  "The maximum gross value of the range."
  grossMaximum: Float

}

"Type for capturing a range banded by floats."
type FloatRange {

  "The minimum value of the range."
  minimum: Float

  "The maximium value of the range."
  maximum: Float

}

"Type for capturing a range banded by integers."
type IntegerRange {

  "The minimum value of the range."
  minimum: Int

  "The maximium value of the range."
  maximum: Int

}

"Type for capturing the name and location of an document."
type Document {

  "The name of the document - a user defined value."
  name: String!

  "The name of the file as it is stored in the document store."
  fileName: String

  "Optional description of the contents of the document."
  description: String

  "The location of the document."
  url: AWSURL

}

"Type for capturing a security identifier and it's type."
type SecurityIdentifier {

  "The value of the identifier."
  identifier: String

  "The type of the identifier."
  type: SecurityIdentifierType

}

"List of available security identifier types."
enum SecurityIdentifierType {
  ISIN
  CUSIP
  SEDOL
  TICKER
  LEI
}

"""
List of available currency codes - currently contains G20 plus Switzerland, Luxembourg and Cayman Islands.
Should be extended to all ISO country codes.
"""
enum CurrencyISOCode {
  ARS
  AUD
  BRL
  CAD
  CHF
  CNY
  EUR
  GBP
  INR
  IDR
  JPY
  KRW
  MXN
  RUB
  SAR
  TRY
  USD
  ZAR
  NOT_APPLICABLE
}

enum Region {
  AFRICA
  ASIA_PACIFIC
  LATIN_AMERICA_AND_CARIBBEAN
  EUROPE
  MIDDLE_EAST
  NORTH_AMERICA
  GLOBAL
}

"""
List of available country codes - currently contains G20 plus Switzerland, Luxembourg and Cayman Islands.
NOTE:
Should be extended to all ISO country codes.
Potentially we could also include supra-national codes.
"""
enum CountryISOCode {
  AR
  AU
  BR
  CA
  CH
  CN
  CY
  LU
  FR
  DE
  IT
  GB
  GG
  IN
  ID
  JP
  KR
  MX
  RU
  SA
  TR
  US
  ZA
}

"List of all available ISO State codes."
enum StateISOCode {
  AL
  AK
  AZ
  AR
  CA
  CO
  CT
  DE
  FL
  GA
  HI
  ID
  IL
  IN
  IA
  KS
  KY
  LA
  ME
  MD
  MA
  MI
  MN
  MS
  MO
  MT
  NE
  NV
  NH
  NJ
  NM
  NY
  NC
  ND
  OH
  OK
  OR
  PA
  RI
  SC
  SD
  TN
  TX
  UT
  VT
  VA
  WA
  WV
  WI
  WY
}`;

export default schema;
